body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: 'Montserrat', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  color: rgb(49, 48, 48) !important;
  line-height: 26px !important;
  overflow-x: hidden !important;
}

/* navlink classess */
.red {
  color: red;
}
.gray {
  color: #333;
}
